.home {
  width: 100%;
  height: 100vh;
  display: flex;
  gap: 0;
  position: relative;
  z-index: 999999999;
  .dressesDiv {
    background: url("../../assets/images/DressesCover.png");
    background-size: cover;
  }
  .makeupDiv {
    background: url("../../assets/images/MakeupCover.png");
    background-size: cover;
  }
  a {
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
      color: var(--White, #fff);
      margin-top: 51px;
      /* Heading 1 */
      font-family: Newsreader;
      font-size: 60px;
      font-style: italic;
      font-weight: 300;
      line-height: 68px; /* 113.333% */
    }
    img {
      opacity: 0;
      transition: 0.4s;
      transform: translateY(10px);
      rotate: 90deg;
    }
    &:hover img {
      opacity: 1;
      transform: translateY(0px);
      rotate: 0deg;
    }
  }
}

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  z-index: 999;
}

@media screen and (max-width:811px) {
  .home {
    flex-direction: column;
    width: 100vw;

    a {
      width: 100%;
      height: 50% !important;
    }
  }
}
