.page {
  padding: 70px 70px 70px 144px;
  justify-content: space-between;
  display: flex;
  height: 100vh;
  position: relative;

  .background {
    z-index: -1;
    left: 0;
    top: 0;
    position: fixed;
    background: #000;
    width: 100vw;
    height: 100vh;
  }

  .information {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-right: 70px;

    .textContent {
      width: 100%;
      h1 {
        color: var(--White, #fff);

        /* Heading 3 */
        font-family: Newsreader;
        font-size: 40px;
        font-style: italic;
        font-weight: 400;
        line-height: 48px; /* 120% */
      }

      .priceInfo {
        display: flex;
        gap: 15px;
        margin-top: 15px;
        h2 {
          background-color: #fff;
          color: var(--Black, #030303);
          padding: 6px 12px;
          border-radius: 20px;
          /* Accent */
          font-family: "General Sans";
          font-size: 14px;
          font-style: normal;
          font-family: "General Sans Medium";
          font-weight: 1000;
          line-height: 16px; /* 114.286% */
          text-transform: uppercase;
        }
      }

      p {
        width: 450px;
        margin-top: 30px;
        color: var(--White, #fff);

        /* Paragraph */
        font-family: "General Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px; /* 160% */

        opacity: 0.9;
      }
    }

    .dropdownandbuttonSpacing {
      margin-top: 76px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .dressInformation {
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        .detajetTitle {
          color: #fff;
          font-family: "General Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px; /* 100% */
          text-transform: uppercase;
        }
        .dressDetajetContent {
          color: #fff;
          font-family: "General Sans";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
        }
        .horizontalLine {
          opacity: 0.15;
          background: #fff;
          width: 100%;
          height: 1px;
        }
      }

      .button {
        margin-bottom: 40px;
        width: 100%;
      }
    }
  }

  .galleryWrapper {
    position: relative;
    height: 100%;
    width: 70%;

    .Arrow {
      position: absolute;
      height: 30px;
      width: 30px;
      left: 25px;
      top: calc(50% - 15px);
    }

    .gallery {
      align-items: center;
      overflow-x: hidden;
      scrollbar-width: none;
      display: flex;
      width: 100%;
      height: 100%;

      flex-shrink: 0;
      gap: 0px;

      &::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        flex-shrink: 0;
      }
    }

    .gallerySelectionWrapper {
      position: absolute;
      width: 100%;
      height: 12px;
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
      bottom: 42px;

      .gallerySelectDot {
        background: #fff;
        border-radius: 20px;
        width: 8px;
        height: 8px;
        transition: 0.2s;
      }

      .gallerySelectedDot {
        transition: 0.2s;
        background: #fff;
        border-radius: 20px;
        width: 12px;
        height: 12px;
      }
    }
  }
}

.dressSliderImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 550px) {
  .page {
    padding: 100px 15px !important;
    flex-direction: column-reverse !important;

    .galleryWrapper {
      .gallery {
        width: 100% !important;
        gap: 0px !important;

        img {
          width: 100% !important;
        }
      }

      .gallerySelectionWrapper {
        bottom: 30px !important;
      }
    }
  }

  .textContent {
    p {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 811px) {
  .page {
    padding: 170px 70px;
  }

  .galleryWrapper {
    width: 100%;
  }
}

@media screen and (max-width: 1331px) {
  .page {
    height: unset;
    flex-direction: column;
  }

  .dropdownandbuttonSpacing {
    height: 400px !important;
  }

  .information {
    padding: 0 !important;
  }

  .dropdownContainer {
    width: 100% !important;
  }

  .galleryWrapper {
    width: calc(100% + 12px) !important;

    &:hover {
      overscroll-behavior-y: contain;
    }
  }

  .gallery {
    width: 100% !important;
    gap: 24px !important;
  }

  .gallerySelectionWrapper {
    bottom: -40px !important;
  }

  .images {
    width: calc(50% - 12px) !important;
  }
}

@media screen and (max-width: 553px) {
  .textContent {
    p {
      width: 100% !important;
    }
  }
  .dropdownandbuttonSpacing {
    height: 300px !important;
  }
  .galleryWrapper {
    width: 100% !important;
  }
}
