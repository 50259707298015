.contactWrapper {
  img {
    z-index: -2;
    position: fixed;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    filter: grayscale(100%);
  }

  .backdrop {
    backdrop-filter: blur(12.5px);
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .contactPage {
    padding: 80px 10px 60px 144px; /*PADDING FOR THE HEADER, REMOVE IF HEADER MOVES OTHER ELEMENTS WHEN ADDED TO PAGE*/
    z-index: 0;
    width: 100vw;
    height: 100vh;

    .information {
      width: 100%;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      h1 {
        color: #fff;
        font-family: Newsreader;
        font-size: 40px;
        font-style: italic;
        font-weight: 300;
        line-height: 50px; /* 125% */
      }

      .contactInformation {
        flex-wrap: wrap;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 30px;

        p {
          color: #fff;
          font-family: "General Sans";
          font-size: 15px;
          font-weight: 400;
          line-height: 26px;
          text-align: end;
        }
        a {
          color: #fff;
          font-family: "General Sans";
          font-size: 15px;
          font-weight: 400;
          line-height: 26px;
          text-align: end;
          text-decoration: none;
          cursor: pointer;
        }

        span {
          color: #fff;
          font-family: "General Sans";
          font-size: 15px;
          font-style: italic;
          font-family: "General Sans Medium";
          font-weight: 1000;
          line-height: 26px; /* 173.333% */
        }
      }
    }

    .entriesWrapper {
      width: 100%;
      display: flex;
      justify-content: center;

      .entries {
        margin-top: 63px;
        width: 570px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;

        .messageEntry {
          display: flex;
          height: 261px;
          padding: 16px 20px;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;
          background-color: rgba($color: #000000, $alpha: 0);
          font-family: "General Sans";
          color: #fff;
          border-radius: 20px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          resize: none;
          transition: 0.5s;
        }

        input {
          display: flex;
          padding: 16px 20px;
          align-items: center;
          gap: 10px;
          width: 100%;
          border-radius: 50px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          background: rgba($color: #000000, $alpha: 0);
          color: #fff;
          font-family: "General Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          transition: 0.5s;
          outline: none;
          &::placeholder {
            color: #fff;
          }
        }
        textarea {
          outline: none;
          &::placeholder {
            color: #fff;
          }
        }

        .namesEntries {
          display: flex;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;

          input {
            width: 50%;
          }
        }

        .buttonWrapper {
          width: 100%;
          margin-top: 26px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 838px) {
  .contactInformation {
    flex-direction: column;
    align-items: center !important;
    gap: 5px !important;
  }
}

@media screen and (max-width: 811px) {
  .contactPage {
    padding: 90px 10px 10px 10px !important;
  }
}

@media screen and (max-width: 450px) {
  .namesEntries {
    flex-direction: column;

    input {
      width: 100% !important;
    }
  }
}
